


















































































































































import Vue from 'vue';
import combinationTable from '../components/combinationTable.vue';
import costCombination from './combination/index.vue';
import { TableActionType, QyStlBillDeType } from '@/interface/costQuotaLibrary';
import TitleInfo from '../../materialMachineLibrary/component/TitleInfo.vue';
import quotaDetail from '../components/quotaDetail.vue';
import { saveToMerge } from '@/api/costQuotaLibrary/costLibrary';
import {
  updateQdAgainMath,
  againMathQdDePrice,
} from '@/api/costQuotaLibrary/detail';
import { queryMergeQdModelList, qydeReceive } from '@/api/project';
import type { QyStlBillDeParamsType } from '@/api/costQuotaLibrary/costLibrary';
import { mapGetters } from 'vuex';
import { costBeforeHandler } from '../index';
import cloneDeep from 'lodash/cloneDeep';

interface CurrentRecordType {
  index?: number;
  parentIndex?: number;
  record?: QyStlBillDeType;
}
interface ProjectQueryType {
  sequenceNbr: string;
  spaceId: string;
  projectSource: string;
  qdSequenceNbr?: string;
  type: string;
  projectBidSequenceNbr: string;
  qdType?: string;
  biddingType?: number;
}

const actionList = [
  // {
  //   type: TableActionType.COPY,
  //   name: '复制清单项',
  // },
  {
    type: TableActionType.COST,
    name: '定额套用',
  },
];
export default Vue.extend({
  name: 'quotaAdjustment',
  props: {},
  components: {
    combinationTable,
    costCombination,
    TitleInfo,
    quotaDetail,
  },
  computed: {
    ...mapGetters(['userInfo']),
    rowSelection() {
      return {
        onChange: (selectedRowKeys) => {
          this.selectedRowKeys = selectedRowKeys;
        },
      };
    },
    quotaEditField() {
      const quotaRef = this.quotaRef;
      if (quotaRef && quotaRef.isDataChange) return [];
      return ['quantity'];
    },
    title(): string {
      const { type } = this.currentProjectQuery!;
      const list = { 1: '未组价', 2: '特殊清单项', 3: '组价推荐' };
      const text = list[type] || '';
      return `${text}定额明细调整`;
    },
  },
  watch: {},
  data() {
    return {
      tableData: [] as Partial<QyStlBillDeType>[],
      deleteVisible: false,
      costVisible: false,
      cancelVisible: false,
      selectedRowKeys: [],
      actionList,
      currentActionRecord: null as QyStlBillDeType | null, // 当前操作数据
      isOpen: false,
      quotaFiledSlot: ['bdCode', 'bdName'],
      currentRecordInfo: { record: {} } as CurrentRecordType,
      quotaRef: null as any,
      currentProjectQuery: null as ProjectQueryType | null,
      applyVisible: false,
      spinning: true,
      isRequest: false,
      originalData: [] as Partial<QyStlBillDeParamsType>[],
    };
  },
  created() {
    this.currentProjectQuery = JSON.parse(
      sessionStorage.getItem('ysf_currentProjectQuery') || '{}'
    );
  },
  mounted() {
    this.getQdList();
    this.$nextTick(() => {
      this.quotaRef = this.$refs.quotaDetail;
    });
  },
  methods: {
    /**
     * 获取清单定额明细
     */
    getQdList() {
      const {
        type,
        qdSequenceNbr,
        sequenceNbr,
        qdType,
        biddingType,
        projectBidSequenceNbr,
      } = this.currentProjectQuery!;
      let params: { type: string; sequenceNbr?: string; constructId: string } =
        { type, constructId: projectBidSequenceNbr };
      if (qdSequenceNbr && qdType) {
        Object.assign(params, {
          sequenceNbr: qdSequenceNbr,
          qdType,
          biddingType,
        });
      }
      queryMergeQdModelList(params).then(({ status, result }) => {
        if (status === 200) {
          // 重新计算
          againMathQdDePrice(result).then(
            ({ status: aStatus, result: aResult }) => {
              if (aStatus === 200 && aResult) {
                this.tableData = this.tableDataInit(aResult);
                this.originalData = cloneDeep(this.tableData);
                this.spinning = false;
              }
            }
          );
        }
      });
    },
    /**
     * 市场价格初始重置
     * type0 赋值初始市场价
     * type1 重置初始价格为初始状态值
     */
    tableDataInit(tableData, type = 0) {
      return tableData.map((qdItem) => {
        qdItem.deList = qdItem.deList?.map((deItem) => {
          deItem.adsQycbRcjModelList = deItem.adsQycbRcjModelList?.map(
            (item) => {
              if (type) {
                item.marketPrice = item.initMarketPrice;
              } else {
                item.initMarketPrice = item.marketPrice;
              }
              return item;
            }
          );
          return deItem;
        });
        return qdItem;
      });
    },
    /**
     * 重新计算价格
     */
    againCalc(qdIndex = -1, deIndex = -1) {
      let params: QyStlBillDeParamsType = {
        qyStlBillDeList: this.tableData,
      };
      if (deIndex >= 0) params.deIndex = deIndex;
      if (qdIndex >= 0) params.qdIndex = qdIndex;
      this.isRequest = true;
      updateQdAgainMath(params)
        .then(({ status, result }) => {
          if (status === 200 && result) {
            this.tableData.splice(qdIndex, 1, result);
            const { index, parentIndex, record } = this.currentRecordInfo;
            if (!parentIndex && parentIndex !== 0) return;
            this.$set(
              this.currentRecordInfo,
              'record',
              this.tableData[parentIndex!]?.deList![index!]
            );
          }
        })
        .finally(() => {
          this.isRequest = false;
        });
    },
    /**
     * 材料应用
     */
    materialApply() {
      const { index, parentIndex, record } = this.currentRecordInfo;
      this.tableData[parentIndex!].deList?.splice(index!, 1, record!);
      this.againCalc(parentIndex, index);
    },
    /**
     * 查看人材机明细
     */
    viewRCJList(record, index, parentIndex) {
      record.adsQycbRcjModelList = (record.adsQycbRcjModelList || []).map(
        (item) => {
          item.sequenceNbr = item.id + new Date().getTime().toString(6);
          return item;
        }
      );
      this.$set(this.currentRecordInfo, 'record', record);
      this.$set(this.currentRecordInfo, 'index', index);
      this.$set(this.currentRecordInfo, 'parentIndex', parentIndex);
    },
    actionHandler({ record, key, index }) {
      this.actionHandlerByType({ record, key, index });
    },
    quotaActionHandler({ record, key, index, parentIndex, parentRecord }) {
      if (this.isMaterialChange()) return;
      if (key === TableActionType.DELETE) {
        this.tableData[parentIndex]?.deList?.splice(index, 1);
        this.deleteMaterial(parentIndex, index);
        let deIndex = index - 1;
        if (deIndex < 0) {
          // 没有定额综合价格药重置
          this.$set(this.tableData[parentIndex], 'price', 0);
          this.againCalc(parentIndex);
          return;
        }
        this.againCalc(parentIndex, deIndex);
      }
    },
    actionHandlerByType({ record, key, index }) {
      if (this.isMaterialChange()) return;
      const map = {
        [TableActionType.COST]: this.costHandler,
        [TableActionType.COPY]: this.copyHandler,
      };
      return map[key]({ record, index });
    },
    costHandler({ record, index }) {
      costBeforeHandler(this, record, index);
    },
    /**
     * 复制清单项
     */
    copyHandler({ record, index }) {
      const { bdCode, bdName, unit } = record;
      let data = {
        sequenceNbr: new Date().getTime().toString(10),
        bdCode,
        bdName,
        projectAttr: '',
        unit,
        price: null,
      };
      this.tableData.splice(index + 1, 0, data);
      // this.againCalc();
    },
    /**
     * 组价定额保存回调
     */
    combinationSaveCallback(list) {
      if (!this.currentActionRecord) return;
      const customIndex = this.currentActionRecord?.customIndex;
      if (customIndex || customIndex === 0) {
        let parentData = this.tableData[customIndex];
        parentData.deList = list;
        this.tableData.splice(customIndex, 1, parentData);
      }
      this.againCalc(customIndex);
    },
    editHandler({ record, key, index }) {
      this.tableData.splice(index, 1, record);
      // this.againCalc();
    },
    quotaEditHandler({
      record,
      key,
      index,
      parentIndex,
      parentRecord,
      eventName,
    }) {
      const deList =
        (this.tableData[parentIndex] as QyStlBillDeType).deList || [];
      deList.splice(index, 1, record);
      if (!record.isError) {
        this.againCalc(parentIndex, index);
      }
    },
    deleteModal() {
      if (this.isMaterialChange()) return;
      if (!this.selectedRowKeys.length)
        return this.$message.error('请先选择要删除的清单');
      this.deleteVisible = true;
    },
    deleteHandler() {
      const selectedRowKeys: string[] = this.selectedRowKeys;
      this.tableData = this.tableData.filter((item, index) => {
        this.deleteMaterial(index);
        return !selectedRowKeys.includes(item.sequenceNbr as string);
      });
      this.deleteCancel();
      this.selectedRowKeys = [];
    },
    deleteMaterial(qdIndex = -1, deIndex = -1) {
      const { parentIndex, index } = this.currentRecordInfo;
      if (
        qdIndex >= 0 &&
        (parentIndex || parentIndex === 0) &&
        parentIndex !== qdIndex
      ) {
        return;
      }
      if (deIndex >= 0 && deIndex !== index) {
        return;
      }
      this.currentRecordInfo = {
        record: undefined,
      };
    },
    deleteCancel() {
      this.deleteVisible = false;
    },
    batchOpen() {
      if (!this.tableData.length) return;
      let keys: string[] = [];
      if (!this.isOpen) {
        keys = this.tableData.map((item) => {
          return item.sequenceNbr as string;
        });
      }
      this.isOpen = !this.isOpen;
      if (keys.length) {
        (this.$refs.combinationTable as any).setExpandedRowKeys(keys);
        return;
      }
      (this.$refs.combinationTable as any).clearExpandedRowKeys();
    },
    apply() {
      if (!this.isSave()) return;
      this.applyVisible = true;
    },
    save() {
      if (!this.isSave()) return;
      this.spinning = true;
      const { openId, agencyCode } = this.userInfo;
      this.applyVisible = false;
      saveToMerge({
        xiliOpenId: openId,
        qyStlBillDeList: this.tableData,
        agencyCode: agencyCode,
        rawData: this.originalData,
      })
        .then(async ({ status, result }) => {
          if (status === 200 && result) {
            const { projectBidSequenceNbr } = this.currentProjectQuery!;
            const { status, result: receiveResult } = await qydeReceive({
              tbId: projectBidSequenceNbr,
              qdList: result,
            });
            if (status === 200 && receiveResult) {
              sessionStorage.setItem(
                'receiveResult',
                JSON.stringify(receiveResult)
              );
              this.applyVisible = false;
              this.spinning = false;
              this.$message.success(
                '保存成功,等待页面跳转',
                1.5,
                this.closeTab
              );
              return;
            }
          }
          this.$message.error('保存失败');
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    /**
     * 定额材料是否有修改，未应用
     */
    isMaterialChange() {
      const quotaRef = this.$refs.quotaDetail as any;
      if (quotaRef && quotaRef.applyTipHandler()) {
        // 数值有变动为保存应用
        this.$message.warning('数据有修改请点击应用');
        return true;
      }
      return false;
    },
    isSave() {
      const tableData = this.tableData;
      if (!tableData.length) {
        this.$message.warning('请添加清单');
        return false;
      }
      if (this.isMaterialChange()) {
        // 数值有变动为保存应用
        return false;
      }
      let keys: string[] = [];
      for (let i = 0; i < tableData.length; i++) {
        const item = tableData[i];
        const { bdCode = '', bdName, projectAttr, unit } = item;
        const key = bdCode + bdName + projectAttr + unit;
        if (projectAttr === '') {
          this.$message.warning('项目特征不能为空');
          return false;
        }
        if (!item.deList || !item.deList.length) {
          this.$message.warning('请先关联定额');
          return false;
        }
        if (keys.includes(key)) {
          this.$message.warning('清单有重复数据！');
          return false;
        }
        keys.push(key);
        const deList = item.deList;
        for (let k = 0; k < deList.length; k++) {
          const deItem = deList[k];
          if (deItem.isError) {
            this.$message.warning('请检查数据是否正确');
            return false;
          }
        }
      }
      return true;
    },
    closeTab() {
      const filterTags = this.$store.getters.tags.filter(
        (tag) => tag.path !== this.$route.path
      );
      this.$store.commit('SET_TAGS', filterTags);
      this.goProject();
    },
    cancel() {
      //if (this.tableData.length) {
        this.cancelVisible = true;
        return;
      //}
    },
    /**
     * 应用取消
     * 需要重置材料价格
     */
    applyCancel() {
      this.tableData = this.tableDataInit(this.tableData, 1);
      const { index, parentIndex, record } = this.currentRecordInfo;
      this.$set(
        this.currentRecordInfo,
        'record',
        this.tableData[parentIndex!]?.deList![index!]
      );
      this.applyVisible = false;
    },
    /**
     * 取消提示中的取消
     */
    cancelHandler() {
      this.cancelVisible = false;
    },
    /**
     * 取消提示中的确定
     */
    cancelClearHandler() {
      this.tableData = [];
      this.cancelHandler();
      this.goProject();
    },
    goProject() {
      const { sequenceNbr, spaceId, projectSource } = this.currentProjectQuery!;
      this.$router.push({
        path: '/ysf/project/detail',
        query: {
          sequenceNbr,
          spaceId,
          projectSource,
        },
      });
    },
  },
});
